<template>
  <div>
    <div class="world quotes-page" v-if="dealer">
      <div class="wrapper-title">
        <div class="container">

          <router-link :to="{name: 'ww.dashboard'}" class="text-wilmsLightBlue font-100 block">&larr; {{ $t('Back to overview') }}</router-link>
          <div class="flex">
            <h1>{{ $t('Offertes') }}</h1>
            <div class="buttons">
              <button :disabled="isLoading" @click="createQuote" class="btn">{{ $t('Nieuwe offerte') }}</button>
            </div>
          </div>

          <div class="intro"></div>
        </div>
      </div>

      <div class="quotes-wrapper">
        <div class="container">
          <div class="table-wrapper quotes" v-if="quotes.length > 0">
            <div class="table-element">
              <table>
                <thead>
                <tr>
                  <th>{{ $t('Nummer') }}</th>
                  <th>{{ $t('Dealer') }}</th>
                  <th>{{ $t('Naam klant') }}</th>
                  <th>{{ $t('Prijs incl. btw') }}</th>
                  <th>{{ $t('Laatst gewijzigd door') }}</th>
                  <th>{{ $t('Aangemaakt door') }}</th>
                  <th>{{ $t('Aangemaakt op') }}</th>
                  <th>{{ $t('Status') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr @click="$router.push({name: 'ww.quotes.detail', params: { id: quote.id }})" @contextmenu.prevent.stop="quoteRightClick($event, quote)" class="quote" v-for="quote in quotes" :key="quote.id">
                  <td class="number">{{ quote.id }}</td>
                  <td>{{ quote.dealer ? quote.dealer.name : '' }}</td>
                  <td>
                    {{ quote.address && quote.address.first_name ? quote.address.first_name : '' }}
                    &nbsp;
                    {{ quote.address && quote.address.last_name ? quote.address.last_name : '' }}
                  </td>
                  <td>{{ $n(quote.calculated_total_with_vat, 'currency', 'nl-BE') }}</td>
                  <td>{{ quote.last_updated_by ? quote.last_updated_by.profile.first_name + ' ' + quote.last_updated_by.profile.last_name : '' }}</td>
                  <td>{{ quote.created_by ? quote.created_by.profile.first_name + ' ' + quote.created_by.profile.last_name : '' }}</td>
                  <td>{{ datetime.fromISO(quote.created).setLocale(activeLocale).toFormat('dd.LL.yyyy HH:mm') }}</td>
                  <td class="status-td">
                    <div v-if="quote.status" class="status" :class="'status-' + quote.status.status">{{ statuses[quote.status.status] }}</div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else>{{ $t('Er zijn momenteel nog geen offertes.') }}</div>
        </div>
      </div>
    </div>
    <vue-simple-context-menu element-id="vueSimpleContextMenu" :options="contextMenuOptions" ref="vueSimpleContextMenu" @option-clicked="contextOptionClicked"></vue-simple-context-menu>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { ApiService } from '@/services/admin/api.service'

export default {
  data () {
    return {
      quotes: [],
      datetime: DateTime,
      statuses: {},
      contextMenuOptions: [],
      isLoading: true
    }
  },
  async mounted () {
    await this.setDealer()
    await this.fetchQuoteStatuses()

    if (!this.checkPermissions(['quote.edit'])) {
      await this.$router.push({ name: 'ww.dashboard' })
    }

    if (this.dealer) {
      await this.fetchQuotes()
    } else {
      await this.$router.push({ name: 'ww.dashboard' })
    }

    this.isLoading = false
  },
  methods: {
    async fetchQuoteStatuses () {
      await ApiService.fetchQuoteStatuses().then(res => {
        for (const status of res.data) {
          this.statuses[status.status] = this.$t('status_' + status.status)

          this.contextMenuOptions.push({
            status: status,
            name: this.statuses[status.status]
          })
        }
      })
    },
    async createQuote () {
      this.isLoading = true

      const quote = {
        dealer_id: this.dealer.id,
        expires_on: DateTime.now().plus({ days: 30 }).toISODate()
      }

      await ApiService.createQuote(quote).then(async res => {
        if (res.status === 200) {
          await this.$router.push({ name: 'ww.quotes.detail', params: { id: res.data.id } })
        }
      })

      this.isLoading = true
    },
    async fetchQuotes () {
      await ApiService.fetchQuotes(this.dealer.id).then(res => {
        this.quotes = res.data
        this.quotes.sort((a, b) => a.id > b.id ? 1 : -1)
      })
    },
    quoteRightClick: async function (event, quote) {
      this.$refs.vueSimpleContextMenu.showMenu(event, quote)
    },
    contextOptionClicked: async function (event) {
      const quote = event.item
      quote.status_id = event.option.status.id

      delete quote.address
      delete quote.extras
      delete quote.signature_dealer
      delete quote.signature_client
      delete quote.articulating_screens
      delete quote.shutters
      delete quote.screens
      delete quote.created_by
      delete quote.last_updated_by
      delete quote.dealer
      delete quote.status

      await ApiService.patchQuote(event.item.id, quote)
      await this.fetchQuotes()
    }
  }
}
</script>
